{
   "authors": "6236539b329a159ebea3fd5e0b68a99ceb36d52fa84cdf3338a3fa53f6fb9500",
   "getDetailAuthor": "1bd29430ce08920fec3f0a3fd6fc58c3cb30f68bcf74e88eef1b163438f360ec",
   "getUserIdFromUsername": "0d12c0e8003968c8fd95c2239d29eacd89e98a08ca1a3fb76fe26d69aa9aa4a3",
   "bookmarks": "66f0655ad464c3df10b46e7cac2c649fbda659ec37bad6cf818bd721905918e1",
   "createBookmark": "24d36991e8bde40a9f8e0b771e3bc0744f5670aa1aacb78afe6a9ffd1544ffd5",
   "deleteBookmark": "0fc9d38c52932c5adc0a30a559c4c47f03803aad95ca12d0f2e639ec00fcf755",
   "getBooksEligibleContest": "31fbc85b7bb508d1d6d080495c6002d6e160d4ac051a2f04426b01b09bce9c10",
   "createBooksContest": "efba3bf2c72a953279905e8ee44f34f02543b545f0f0a56e85916607df535e55",
   "getBookInfo": "d5b3fb15a8aa45b72ce6b29fb603241089b9b75ee0a40d916cc60fa5c7723c59",
   "getBookStatisticInfo": "8df1e13a929d089ea98469c1baf3ea760f357a3abc324da9714e217e16e63d66",
   "allBooksPrices": "e02c9f337dc11fe67e4673093772b7faff13ed5d0120060dec1c1eaa3344dcd3",
   "booksPrice": "036599e6728f588a5d970c3d224fc2089a2e2049a69f64e16c90d6211cfd0272",
   "pricesByUserId": "b16ba8ca48ccac5565fc12830a06f7913a314828aae9b634acda6eba4c1c759f",
   "discountsByUserId": "2efb8478e759e8a2588e2858099b944f2eb406679801da533d8378c44335803c",
   "createPrice": "237d50042504670a4be1838f78a62610c39b4499951866885c094e0f095fe243",
   "createDiscount": "1226ca1d00ba7766407dcfa27682b6c11d8d2d8e0f9db1d0df81587059794ad7",
   "writePrices": "46227e3b72cc176c94374b3021731162777478ee74656ebe649ad71988277c26",
   "deletePriceByPriceId": "a9f338fa7b2f0091772832c0effd07dd36ee9124613cd2342aca4fa70bae78d5",
   "deleteDiscountbyDiscountId": "efade963bdbc831258de8429042ce1c7e1479019313af841f0a4c961b71e82cf",
   "updateUpdatedAtBook": "77465f2cfd411d8cafc9f17627f472647e135872e9e9c9a949dc56409deb5db0",
   "quickSearch": "75896398d73d6db0bdbb2e17805a78c33341c66bd4829a4664df56924a2be630",
   "buyBookNow": "65cde67e44591a5ba1fd8582c9cfd2c55ef878c461ef6929a1e5b72098a1e994",
   "checkPrice": "b0deb03ec4c86d660c95e0956d7e3e5795b760cecfda7699ad03ce45b5312572",
   "donateBookNow": "48684b590ce6be9fabd568194f0e87dd018d54ed39210134657e3ca7669fcea5",
   "createBookShare": "815948b38e1a1272d2a6df192d106eb5b2e56b43ec719c8f4e0538fab016c44a",
   "readBook": "25bc415c6385a17902f4fe32e1ca066f512dabcd20bd379eaa2b43edc73bcabe",
   "bookAllies": "b7ea87ab9c35b3895ae82d788c8e7110ce57c006446bd951109c3223db8d3918",
   "bookDailies": "a81272578b27f59831ea13cbc258cbe94170694c486761ec3358a66db08520bb",
   "bookMonthlies": "db4f1e39baab544b3f6a5a45b13102e3c80409a3987afc612d0080cbb446ceef",
   "bookQuarterlies": "485ebf47fe4094e81eb7e1a906067612aa3a43432f4d55c8e55bde769004d96d",
   "bookWeeklies": "dd4801b6ee0b12f550d4d2e84dca6753b94ea66de653cd7b82655421fca98197",
   "bookYearlies": "c5c49fc40c0a6ccbcef9fc6b50a97a5a915b1103a0d2ab914306ab7bbc6e8487",
   "oneCart": "a548133b4957ce930161e384349553adb0b0a323af3083fddf5f66583b26b299",
   "allCarts": "b0b352871333eb3b5b464648d27470dd362e7854d8b986e1826ebc85559d94c1",
   "createCart": "cd5e439234dab2a191fee15f63bbde570a76cb5ce0d6979b59910edd435c40b6",
   "deleteCart": "6bb531c18b570a3ceb144a248530290e53a8ca5f7d754cfbc68d02fceae00a43",
   "getAllBookSalesOfReader": "5897dcde1e9d731adcc7842af52559fc05d23650c82b7d704f171ebfe28dcacf",
   "getAllBookSalesOfWriter": "f258c9e973f1dd49c821f4f27b86e1d724b7f498237ee4f0fbac4de3bd7c74db",
   "allBookSalesByMonth": "44ae7e24a1a17e3f93daeb1c29d4a3af1e7673b9ec1e22dadf0b45626bc89fb1",
   "vault": "ad0e99b671c84c3600203ed23c2dadfc9a36efe9ef5a6729ee350c9f0add32fd",
   "allCategories": "74b5a7374220e5ac109429dec388b6fdaaa549bc24510945973f69cf68712b36",
   "categoryById": "0ca1d24581ae7ee996e39358abfa3e40d4c97c840c55a035d47cb69e387793e1",
   "checkNidChapters": "31b806b82db6fe8af11e4dad9e27e5c5ea723aaae3fec502931e9a28abc00a87",
   "updateChapter": "a2db41b7128dc7638bcae25a7e3e9b638a3511450db43d675fd6a5f8a34b03bf",
   "createChapter": "2d45eea7dae7301c92ea460ceb08e6e6a60ba0f6002ea44609e6b84d32842aa4",
   "newChapters": "9a56d17796da8d379f0bca00816b564d87c41a6fb3d8d05b6e32e4434a7f8204",
   "delete": "ad3e2360ed7f684a7debd7bf22fb219c821d466b43da0aaa6cd326a97f660891",
   "reduceChapterPosition": "275b70a6c8d8d16387f2e2df9f0e357b07b333875e6bf5819742be43c737472a",
   "latestBookChapters": "f9050d857b7b8323a8005b5ad5833bcb0093169bab54029caa5089610e43520a",
   "fetchChangelog": "252e72f9c8fd9091fcbd2cc1d19f93f91759975f5b83a0acabc3ac8a4e901588",
   "vChapters": "d32117187334d7fb67c3c193ee5d35837e1fd75f58d21b7db172467b146329bf",
   "allChaptersByCursor": "ed74ff3bc98fc4ba61889154978ad7fcbaf73decf9f74b2517d16ab502f06eec",
   "oneChapter": "48ed0a9796abf7f2d00fbc29718c73b79369dd02a6dc33558889d613d9befcef",
   "getChapterContent": "e19f37c53548c6c0d0d268b13beedfd77d6d3c61c490438f9756b14c7331c43b",
   "getCommentByCommentId": "61578439efeb349c2c7305d242c98d7614cbd69488d1660de50d2a19bbc86352",
   "comments": "77c52eabfe1f534f1396d502f163bb1c5def7472f0fbe0a43f707a96a184437a",
   "createComment": "0c78356bdffd6ace6a8b2beef30fe295c37d5a03e71c6967896ee03ffada87e4",
   "updateComment": "03200069a76dd6c59aa53e8f8e61d43ee084c325acc39bf09e9599213af51eca",
   "deleteParentComment": "14c43ab98db219945479d7f134d31ed8bd8ec486c9bd8d60efb5553d2b5455cd",
   "deleteComment": "96f1215249bc3772e280f27e6289ba053a1d4f7627ca11939239af45bd80e87e",
   "allContests": "ea6d2adc17448d7a5f140833587cad7b8f42bbe6948a650d73017badefd4c60e",
   "checkNidBooks": "71a59e47a53d5664aa8361297b3c887057f8e1f029b33a38138c3dbce137f8e0",
   "filterBooks": "bb0873f91af01d37e64b572b5ec4684e7e75f6faa3a55762182f1b6f74311fe8",
   "getOneBook": "35aa18f0e8a4458feee3b916016a70308a080dd61c29e90a151f40c0f1b24389",
   "vSoftBooksWithStatistics": "db2a2074d5cd830356851b4289d73115c9c23b3b45feceaecc08aaac80773fa0",
   "createBook": "df179d8e50c7b9b1d0a31da8c6ba2a9b7f8bf37bcf965050482c46e994d00acd",
   "editBook": "2155bdaa9b7a3f6dd5c43bdbafdbfdef49adee977e3f6177d30c5aadd283f971",
   "deleteBook": "d947aa396ebc792f1a4cc93d41711a4cc7243378029a976479d8b2d054315aae",
   "publishBook": "244207d08084fc7fdb32af0d2399a7c88afb0cd7dedba8dc80fc6a89fbaad381",
   "allDeposits": "94adad1b38dde4aebcbc6d5258da0e3cbff3a8db721daca09b8218d28b1d4cb9",
   "getAllDepositsByUserId": "18a0d44c561d07be2d42559f907964d1262b44a25618db07eee47a295769e2b7",
   "getDepositPendingByUserId": "c6420af4f3651811d819a87401ea5d3a94b4137b63def55a4dab253b95c86702",
   "createDeposit": "ee1245249631a44b8a1e0cd110a6c28816944c592d07017e204e072c5e3c1741",
   "updateDeposit": "b80c2de741bb63a03a8b481d16d48013ed970f44d91169e2f7cf22163af48b14",
   "releaseCoin": "261f1f69f229d08fd1ab5440ac0548426ea76485e029989d5c21a7a6001b530e",
   "getUserWallet": "850160bd522b01d9d6745dcfe412a788d099024cefecc7818bd437bf4070c9a4",
   "createLimit": "7ba2239c02337592c1240057d83068b773da6a2693d5b7c7098fa9b5f5d4ccc7",
   "updateLimit": "d503a2914892f83bf2fede457e10fb995ee617d91f2dca85ee5c45a252cbf7b9",
   "allBookDonates": "19c2bbcfe7e0c3840a7a0cd60378b0459a76b8e61bedc3147c5a7b864964cdde",
   "allBookDonatesByMonth": "16694cad14be9976e40de55777e20683b82ed178a655adad34733f3d41ce4128",
   "allGenres": "3f6b73165792b02e8c25316ec7e6925dd860be01d262289dc87a6f947c141eea",
   "allTrendGenres": "77d98963ecba24e6faf94c29b36d33f31805f681e86229a0de05fb573cf35520",
   "getGenre": "e447fbb7044ed6545c59697c928fa4f9ca04e9d411dc5e800bf90a7a55f830ac",
   "allHistories": "99c6f93e6d8ca83c64657f541d4b8861820810d996148f85017e4ecb33ced758",
   "AllLibraryBooks": "5abd1f6b8ba95667df49a1bb93663ceb929f29a13a2624e634d176b6842e3b1c",
   "AllbuyedBooks": "58c4fc8f4866a6d7a31189f041c4bf4f31258df954a59eef5ea807d6620f165f",
   "AllBookmarksLibraryBook": "e7cf4582ed90751c08991c7d9d73b6f03251d1af8fef0bd3b9a9c6e875af69b7",
   "createBookmarkLibraryBook": "b8ac83369a1b922dcc5a25072fe7056be77eedd5a7526a6b1c33125e4584d59f",
   "updateBookmarkLibraryBook": "877368d676c08ee0a8166e35450226154769422025f7335af348af0ddbb9a0a0",
   "libraryBookByBookId": "0eece2618552bed764f4e57f2c987b7b7fa26f9f6ab449484029d9142d4a60b6",
   "bookNewsAwread": "c5ebc3214bea37187c689873f68e5328f84fe658bf4f710107645a26b17165b5",
   "newsAwread": "a729307f5799d3c552b832580417af2ecdf770c17b8e6df38b735bbeb194bc94",
   "newsShareExp": "2904bef665c3bf516569ec07673a80708499dd809d037e625b4ecf12efe2c68e",
   "contentBlog": "99dbff805c88849a89e75353a3f42927016f6bdc7449fed8db89c68028d35e57",
   "listenToNotifications": "a543a49d25ead3fd0260adcc6b139b274336fcdee92367051d96b41652235686",
   "notiDepositCompleted": "295a58698670d276ebc39da1645981c6f9c51364b524137ffcdb8f382d1a0c6c",
   "allNotifications": "7a6c522e8853070e6b3350da9f499bc675dba44e95578a6c981d70d4e7e8343a",
   "createNoti": "e65c98b41dc22dc712c121313246479c355255e0aa6da7b49202546ade7bd0a0",
   "updateNotification": "96e0c3f1f7fbc53fc1d736b8f89f69da71a33baf8b9b4d5d06246b21248e9ba4",
   "createNotiPaying": "3cf9ba72609c407d12698e1d6c2a3a908d043b80eb3302cdebd1f884532462c4",
   "allNotiLuckyMoney": "2eb65eca253285f74018bf17a97b767f750882f812d3a48a760ae7549a37a9b5",
   "allQuestsByUserId": "cf70d61b1048f5845f931e90793c6d766ab732a6fc30ae59103338edc8198ee8",
   "checkClaimReward": "2a9ed5eb235d86528346c1331133df106fa628797adfdcfcbe92b27f977774c3",
   "getDailyCheckin": "a833f3b4ce1ef951d9fa76600d2f9b330504039347e885f72a6398982b76fbd6",
   "claimDailyCheckin": "d6ec0c250d24ea37a7b6213974d9a1e3205c89bc53792f4592377dfa6208bd56",
   "checkReadingQuest": "eed0b52929d221c82848f350676a77a5febc4abcb16a7b41353b86f64e073f30",
   "checkReviewed": "8918558ce3a2ca0905c22cc08949eebbaa37eadbd49ff3f266d42b4478fc52a2",
   "allReviews": "b95765af5bb15ea181c0a765c541e7f37e5d38776f475a49e6b93f913ac8a467",
   "createReview": "ad6136984ee8a48c085407cb60ddb7c7b4813e4abf7a11edfab45743026af1fc",
   "updateReview": "6d8a48382841c5b6ec01a160094ee5d13bf3458af422bdc42da61ed7e3c13ad7",
   "deleteReview": "796ef9d05ce04c21ecbe910785b240561e4f51a5a187f82817203cae8be77b49",
   "createReviewReaction": "167df7adec0fa3d5c5245b5d73a32e44f873c162248fb415dee06034514c978a",
   "updateReviewReactionByNodeId": "91652caeff5b23cadc45e02a0b3f05d3d06abadf2e6e8a718cdf6f2aa4671c78",
   "deleteReviewReactionByNodeId": "7db52600edb99246037d7920d9779d5a9e63ecd5aae83aacb35e6cb163274469",
   "getAllRewardQuests": "bbccbda1a57d4fb5afab6f6e1992a1e30e9c7753b5e7f9df4c361428d1068515",
   "getAmountTrackReward": "e8244d594c55c9e2d334617f5043e1fb884d1aa3479cbca7cd8aeadec8df5038",
   "getRevenueSaleBook": "782517030e095426e79916cde88dfab637b4b228fe2d5717e40f452c52f36ae5",
   "getInfoActionBookAlltime": "dcd7caa5e202faf4e9818c4bebfcb691cc5813b8d6530e60dc67ae6261d9da6e",
   "getInfoActionBookYearly": "1e331e0c6ccf48012a60e76892596e6dd67f22c00c941cf84596930a3a14fb6c",
   "getInfoActionBookMonthly": "7ed57231f64d39ff6760f5471a59066175643755ec2f7d6fe8e64b181341a73b",
   "getInfoActionBookWeekly": "3f2793aae50709ccfecf383faf5785bb34745b9c3abe8261ce5046a463d2ebba",
   "getInfoActionBookDaily": "f82e502a33e273004f5bddc28563011c42aa489a297dfe44a79faf2157b0c629",
   "allTags": "ecc7d1a8ffa86e8fd88013bc5620c3ea468d3a5dd1bdb71d911ed9b33562b45f",
   "allSearchTags": "5ab4b79326d560081877da375678d46e84a12aba1d8157c8d93c977ef790da3c",
   "allTrendTags": "a90ef5307dca4ba2101860d278356a091f4fe6f6d220005127c9d48980a97aa7",
   "getTag": "408a1e86a0e91c5368d7971fbd2a15a5c8833c834b51ebccaece46bf7b1bcede",
   "createTag": "fe6f894141b29fc978d7b5b9e51c4cb3f29b8df00b717bcec087cee911f2a4d7",
   "upsertBooksTags": "5cfd2aa59108f6297ecee95f8fcdb3de649503123f7b84377345cbbb4d20383b",
   "changePassword": "58eb56cfaf36e0ccee7378d01906a17f4b1a682a85cd9881e7b993125032a1a2",
   "checkNidAuth": "3f138249e0ba9d9943ed64987e677feec627232f475f843c4dc20626f20d07cb",
   "authenticateUser": "a0ae48610459de8b9fbbe04c70226632eb844e42c5c60fd982fa050687f03ca3",
   "registerUser": "649fd769dbab53bca7dabc594705a6350b36807c569f63e0dd620efe24d72a8a",
   "bookUserReading": "aa5b6f6a1cda8a887b424904fbbaf171783f1f7ad18f0185824e47b16559767e",
   "thisUser": "ad5b363a589c649445d1ec37221bdbdb64afd29a20817c2cb4923003d399494e",
   "createPersonal": "29e3dd260861102e691aa494d849c82f467e1619aeb9506c6431cf840bef1a49",
   "updatePersonal": "f1e90b70d3a6ae68ce170ef72459c54332709d2bce5bcf670bc36b1cd3fcd839",
   "updateUser": "e83221e52b9d33138eb62cd0b3eba3b6f07639a1801041c8e28b8f44e46b490e",
   "updateSocial": "11fb40078f6f41fffc165ebdb4f756a6f233c257b53d6a143cd93c9edd84a0a3",
   "refreshToken": "98766036f121ac0f5ac5a8b168a742b612d9f7af2ac3448fdc4177e98ad14c69",
   "upgradeRole": "e933c02f3327c5be4087c5410624297c5583055b6d0605a181342a57bd40091b",
   "updateHaveNoti": "9b88e6b29cd8d8d6f5be2fdeb3262c0f7877064e1a79754409b9456361d099bc",
   "updateHaveDialogNoti": "7a2aace9eeabde212c5de7157c5e2645dd3d16d4c961b53892397321cb2b3130",
   "withdrawProfile": "c3acae94a7844de57a7707532d905fdb872b814222c12db852358f3d4ed36aa5",
   "createWithdrawProfile": "af2e4d239318736c71f37fe50c2644b31346f967a986372799b43e42168c939f",
   "updateWithdrawProfile": "cbdd0041797fc59e81518709b8da62ddbb658bb42206ae47e5ae59a9a54a7425"
}