import { Injectable } from '@angular/core';
import { environment } from '@awread/global/environments';
import { Observable, Subject, mergeMap, delay, tap } from 'rxjs';
declare const FB: any;
declare const google: any;
declare const AppleID: any;

export class SocialUserAddon {
  provider!: string;
  providerId!: string;
  id!: string;
  email!: string;
  name!: string;
  photoUrl!: string;
  firstName!: string;
  lastName!: string;
  authToken!: string;

  idToken?: string; // Reference https://developers.google.com/identity/sign-in/web/backend-auth
  // Reference https://developers.google.com/identity/sign-in/web/backend-auth
  authorizationCode?: string; // Reference https://developers.google.com/identity/sign-in/web/reference#googleauthgrantofflineaccessoptions
  // Reference https://developers.google.com/identity/sign-in/web/reference#googleauthgrantofflineaccessoptions

  response: any;



  get() { }
}

@Injectable({ providedIn: 'root' })
export class SocialLoginAddon {
  appleLogin$ = new Subject<SocialUserAddon>();

  private requestOptions = {
    scope: 'public_profile',
    locale: 'vi_VN',
    fields: 'name,email',
    version: 'v17.0',
    // appId: environment.production ? '178697176886410' : '1687794138050695',
    // appId: true ? '178697176886410' : '1687794138050695',
    appId: '178697176886410',
  };



  logout(provider: any, all = false) {
    switch (provider) {
      case 'facebook':
        FB.logout();
        break;
      case 'apple':
        console.log('not implemented');
        break;
      case 'google':
        console.log('google new version dont have logout');
        break;

      default:
        break;
    }
  }

  destroyGG() {
    return () => {
      // Cleanup function that runs when component unmounts
      google.accounts.id.cancel();
      document.getElementById('google-client-script')?.remove();
    };
  }

  loadGG() {
    return new Observable<any>((resolver) => {
      try {
        if ((window as any).google) {
          resolver.next();
          resolver.complete();
        } else {
          this.loadScript('google-client-script', `//accounts.google.com/gsi/client`, () => {
            resolver.next();
            resolver.complete();
          });
        }
      } catch (err) {
        resolver.error(err);
      }
    });
  }

  loadFB() {
    console.log('start load FB 1');
    return new Observable((resolver) => {
      console.log('start load FB 2 ');
      try {
        if ((window as any).FB) {
          FB.init({
            appId: this.requestOptions.appId,
            autoLogAppEvents: true,
            cookie: true,
            xfbml: true,
            version: this.requestOptions.version,
          });
          // console.log('[FB-addon] inited');
          resolver.next();
          resolver.complete();
        } else {
          this.loadScript('facebook-client-script', `//connect.facebook.net/${this.requestOptions.locale}/sdk.js`, () => {
            FB.init({
              appId: this.requestOptions.appId,
              autoLogAppEvents: true,
              cookie: true,
              xfbml: true,
              version: this.requestOptions.version,
            });
            // console.log('[FB-addon] inited');
            resolver.next();
            resolver.complete();
          });
        }
      } catch (err) {
        resolver.error(err);
      }
    });
  }


  private parseJwt(token: string) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join('')
    );

    return JSON.parse(jsonPayload);
  }

  loadScript(id: string, src: string, onload: any, parentElement: HTMLElement | null = null) {
    // get document if platform is only browser
    if (typeof document !== 'undefined' && !document.getElementById(id)) {
      const signInJS = document.createElement('script');
      signInJS.id = id;
      signInJS.async = true;
      signInJS.src = src;
      signInJS.onload = onload;

      if (!parentElement) {
        parentElement = document.head;
      }

      parentElement.appendChild(signInJS);
    }
  }

  async appleLogin() {
    try {
      const data = await AppleID.auth.signIn();
      this.appleLogin$.next(data);
    } catch (error) {
      //handle error.
    }
  }

  private googleInitBeforeRender(login$: Subject<SocialUserAddon>) {
    google.accounts.id.initialize({
      client_id: '265413764260-sqgbeot6gpv6u7dp6moov7jp2blkvp3i.apps.googleusercontent.com',
      callback: (result: { credential: string }) => {
        const ggUser = this.parseJwt(result.credential);
        const user = new SocialUserAddon();
        user.provider = 'google';
        user.providerId = ggUser.sub;
        user.id = ggUser.sub;
        // TODO: username
        user.name = ggUser.name;
        user.email = ggUser.email;
        user.photoUrl = ggUser.picture;
        user.firstName = ggUser.given_name;
        user.lastName = ggUser.family_name;
        user.authToken = result.credential;
        user.response = ggUser;
        login$.next(user);
      },
    });
  }

  private googleRender(options: any) {
    const id = options.elementId;
    console.log('options', options);
    google.accounts.id.renderButton(document.getElementById(id), {
      theme: 'outline',
      size: (globalThis as any)['isMobile'] ? 'large' : 'large',
      shape: options.google.shape, // 'circle', 'pill',
      type: options.google.type, // icon | standard
      logo_alignment: 'center',
      width: (globalThis as any)['isMobile'] ? 285 : 384,
      // signin_with	The button text is “Sign in with Google”:
      // A standard button labeled 'Sign in with Google' An icon button with no visible text
      // signup_with	The button text is “Sign up with Google”:
      // A standard button labeled 'Sign up with Google' An icon button with no visible text
      // continue_with	The button text is “Continue with Google”:
      // A standard button labeled 'Continue with Google' An icon button with no visible text
      // signin	The button text is “Sign in”:
      text: options.action === 'register' ? 'signin_with' : 'signup_with',
    });
  }

  googleLogin(options: any) {
    const login$ = new Subject<SocialUserAddon>();
    return this.loadGG().pipe(
      tap(() => this.googleInitBeforeRender(login$)),
      delay(10),
      tap(() => this.googleRender(options)),
      mergeMap(() => login$)
    );
  }

  facebookLogin(options?: any) {
    const login$ = new Observable<SocialUserAddon>((resolver) => {
      FB.login((response: { status: string; authResponse: any }) => {
        if (response.status === 'connected') {
          const authResponse = response.authResponse;
          FB.api(
            `/me?fields=name,email,picture,first_name,last_name`,
            (fbUser: { name: string; id: string; email: string; first_name: string; last_name: string }) => {
              const user = new SocialUserAddon();
              user.provider = 'facebook';
              user.providerId = fbUser.id;
              user.id = fbUser.id;
              // TODO: username
              user.name = fbUser.name;
              user.email = fbUser.email;
              user.photoUrl = 'https://graph.facebook.com/' + fbUser.id + '/picture?type=normal&access_token=' + authResponse.accessToken;
              user.firstName = fbUser.first_name;
              user.lastName = fbUser.last_name;
              user.authToken = authResponse.accessToken;
              user.response = fbUser;
              console.log('facebook nexted', user);
              resolver.next(user);
            }
          );
        } else {
          resolver.error(response);
        }
      });
    });
    return this.loadFB().pipe(mergeMap(() => login$));
  }
}
