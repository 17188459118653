import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { restartSubscriptionsClient, currentSocket } from './apollo.link.js';

@Injectable({ providedIn: 'root' })
export class ApolloAddon {
  constructor(private apollo: Apollo) {}

  resetWsConnection() {
    restartSubscriptionsClient();
  }

  closeSocketForBfCache() {
    if (currentSocket) {
      currentSocket.close(1000, 'close for bfcache hidden');
    }
  }

  async clearStore() {
    await this.apollo.client.clearStore();
    this.closeSocketForBfCache();
  }
}
