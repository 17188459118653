import { Injectable } from '@angular/core';
import { AuthGear, AuthRoutingGear, CurrentUserGear, LogoutGear, SocialProvider, RegisterGear } from '../gears/index.js';
import { CreateUserCredential, CurrentUser } from '../models/index.js';
import { CurrentUserQuery } from '../states/current-user/index.js';

@Injectable({ providedIn: 'root' })
export class AuthFacade {
  currentUser$ = this.currentUserQuery.select();
  isLogin$ = this.currentUserQuery.select((state) => !!state.userId);
  get currentUser() {
    return this.currentUserQuery.getValue();
  }

  constructor(
    private logoutGear: LogoutGear,
    private registerGear: RegisterGear,
    private currentUserQuery: CurrentUserQuery,
    private currentUserGear: CurrentUserGear,
    public authRoutingGear: AuthRoutingGear,
    private authGear: AuthGear,
  ) {}

  connectSocial(provider: SocialProvider) {
    return this.authGear.connectSocial(provider);
  }

  login(options: { provider: string; credentialValue?: any; navigate?: string; action: string; elementId?: string; google?: any }) {
    return this.authGear.login(options);
  }

  register({ provider }: { provider: string }) {
    this.authGear.register({ provider });
  }

  logout(redirectLink?: string | undefined, isLogoutRequire?: boolean) {
    this.logoutGear.logout(redirectLink, isLogoutRequire);
  }

  updateUser(user: CurrentUser) {
    return this.currentUserGear.updateUser(user);
  }

  createNewAccount(requiredForm: CreateUserCredential, optionalForm: any, experienceForm: any, provider: string) {
    return this.registerGear.createNewAccount(requiredForm, optionalForm, experienceForm, provider);
  }

  checkAccount(checkFields: any) {
    return this.authGear.checkAccount(checkFields);
  }

  changePassword(form: any) {
    return this.authGear.changePassword(form);
  }
}
