import { Injectable } from '@angular/core';
import { SnackbarService } from '@awread/global/packages';
import { of } from 'rxjs';
import { map, mergeMap, tap, withLatestFrom } from 'rxjs/operators';
import { AuthRoutingGear } from './auth-routing.gear.js';
import { SocialLoginAddon } from '../addons/index.js';
import { AuthApi } from '../apis/index.js';
import { CurrentUserQuery } from '../states/current-user/index.js';
import { LoginGear } from './login.gear.js';
import { RegisterGear } from './register.gear.js';
import { DialogService } from '@ngneat/dialog';

const providers = ['facebook', 'apple', 'google', 'zalo'] as const;
export type SocialProvider = (typeof providers)[number];

@Injectable({ providedIn: 'root' })
export class AuthGear {
  constructor(
    private socialLoginAddon: SocialLoginAddon,
    private loginGear: LoginGear,
    private registerGear: RegisterGear,
    private authApi: AuthApi,
    private snackbarService: SnackbarService,
    private currentUserQuery: CurrentUserQuery,
    private dialogService: DialogService,
    private authRoutingGear: AuthRoutingGear,
  ) {}

  checkAccount(checkFields: any) {
    const validCheckFields = this.validFieldWithCurrentUser(checkFields);
    if (validCheckFields.length === 0) {
      return of([]);
    }
    return this.authApi.checkAccount(Object.fromEntries(validCheckFields)).pipe(
      map((result: any) => {
        return Object.entries(result)
          .filter(([key, value]) => !!value)
          .map(([key, value]) => key);
      }),
      tap((duplicateFields) => {
        if (duplicateFields.length) {
          this.snackbarService.showError(duplicateFields[0] + ' đã đăng ký!', { duration: 2000 });
        } else {
          // this.snackbarService.showSuccess('Thông tin hợp lệ');
        }
      }),
    );
  }

  private validFieldWithCurrentUser(checkFields: any) {
    const currentUser = this.currentUserQuery.getValue() as any;
    let entriesFields = Object.entries(checkFields);
    if (currentUser && currentUser?.userId?.length) {
      entriesFields = entriesFields.map(([key, value]: [any, any]) => {
        const userValue = currentUser[key];
        if (userValue === value) {
          return [key, null];
        } else {
          return [key, value];
        }
      });
    }
    const validCheckFields = entriesFields.filter(([key, value]) => value && (value as string).length);
    return validCheckFields;
  }

  // renderSocial(providers: SocialProvider[], options?: any) {
  //   return this.socialConnectGear.renderCombineSocialButtons(providers, options);
  // }

  connectSocial(provider: SocialProvider) {
    switch (provider) {
      case 'facebook':
        return this.socialLoginAddon.facebookLogin();
      case 'google':
        return of(); // using render
      default:
        console.warn('I dont know this provider', provider);
        return of();
    }
  }

  // we only trigger social connect function, the result is handled by logic on the watch function
  login(options: { provider: string; credentialValue?: any; navigate?: string; action: string; elementId?: string; google?: any }) {
    let login$;
    switch (options.provider) {
      case 'facebook':
        login$ = this.socialLoginAddon.facebookLogin(options);
        break;
      case 'google':
        login$ = this.socialLoginAddon.googleLogin(options);
        break;
      case 'email':
        if (options.action === 'register') {
          this.dialogService.closeAll();
          this.authRoutingGear.navigateRegister();
          return of({ user: null, response: null });
        } else {
          login$ = of(options.credentialValue);
        }
        break;
      default:
        console.warn('I dont know this provider', options.provider);
        login$ = of();
        break;
    }
    return login$.pipe(
      // tap((r) => console.log('STEP 1', r)),
      mergeMap((user) => this.loginGear.checkWithServer(user, options).pipe(map((response) => ({ user, response })))),
      // tap((r) => console.log('STEP 2', r)),
      tap(({ user, response }) => {
        this.loginGear.handleLoginResult(response, options, user);
      }),
    );
  }

  // we only trigger social connect function, the result is handled by logic on the watch function
  async register(options: any) {
    let register$;
    switch (options.provider) {
      case 'facebook':
        register$ = this.socialLoginAddon.facebookLogin(options);
        break;
      case 'google':
        register$ = this.socialLoginAddon.googleLogin(options);
        break;
      default:
        this.dialogService.closeAll();
        this.authRoutingGear.navigateRegister();
        // email register, navigate to register
        register$ = of();
        break;
    }
    return register$.pipe(tap((user) => this.registerGear.createNewAccountFromSocial(user)));
  }

  changePassword(form: any) {
    return this.authApi.changePassword(form);
  }
}
