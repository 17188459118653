import { Injectable } from '@angular/core';
import { AuthApi } from '../apis/index.js';
import { AuthRoutingGear } from './auth-routing.gear.js';
import { SnackbarService } from '@awread/global/packages';
import { CurrentUserGear } from './current-user.gear.js';
import { SocialLoginAddon, SocialUserAddon } from '../addons/index.js';
import { ConfirmComponent } from '@awread/global/packages';
import { RegisterGear } from './register.gear.js';
import { ApolloAddon } from '@awread/global/apollo';
import { DialogService } from '@ngneat/dialog';
import { CurrentUserFacade } from '../facades/index.js';
@Injectable({ providedIn: 'root' })
export class LoginGear {
  constructor(
    private authApi: AuthApi,
    private authRoutingGear: AuthRoutingGear,
    private snackbarService: SnackbarService,
    private currentUserGear: CurrentUserGear,
    private dialogService: DialogService,
    private apolloAddon: ApolloAddon,
    private socialLoginAddon: SocialLoginAddon,
    private registerGear: RegisterGear,
    private currentUserFacade: CurrentUserFacade
  ) { }

  checkWithServer(user: any, options: any) {
    if (options.provider === 'email') {
      return this.authApi.authenticateUser(user);
    } else {
      return this.authApi.authenticateSocialToServer(user);
    }
  }

  handleLoginResult(result: any, options: { action?: string; navigate?: string }, user: SocialUserAddon) {
    const isUserExist = !!result?.user?.userId;
    const isLoggedIn = !!result?.accessToken;
    const loginSuccess = isUserExist && isLoggedIn;
    const loginFailAccountExist = isUserExist && !isLoggedIn;
    const loginFailAccountNotExist = !isUserExist;
    if (loginSuccess) {
      return this.loginSuccess(result, user, options.navigate);
    } else if (loginFailAccountExist) {
      if (result.matchPassword === false) {
        result.caseName = 'password-not-match';
      } else if (result.emailVerified === false) {
        if (result.provider === 'email') {
          result.caseName = 'email-not-verified';
        } else {
          result.caseName = 'account-logged-in-with-social';
        }
      } else {
        result.caseName = 'unknown';
      }
    } else if (loginFailAccountNotExist) {
      if (options.action === 'connect') {
        result.caseName = 'connect';
      } else if (options.action === 'frictionless') {
        result.caseName = 'frictionless';
      } else {
        result.caseName = 'account-not-exist';
      }
    } else {
      result.caseName = 'unknown';
    }
    this.socialLoginAddon.logout(user.provider);
    this.loginFail(result, user);
  }

  loginSuccess(result: { accessToken: string; provider: string; user: { firstname: any; name: any } }, user: SocialUserAddon, navigate?: string) {
    console.log('loginSuccess', result)
    this.setAccessToken(result);
    this.dialogService.closeAll();
    this.apolloAddon.resetWsConnection();
    // this.transferTokenAddon.transfer(result?.accessToken);
    this.getCurrentUser(result, user, navigate, true);
  }

  private getCurrentUser(result: { accessToken: string; provider: string; user: any }, socialUser?: SocialUserAddon, navigate?: string, isLogin?: boolean) {
    this.currentUserGear.fetchUserData().subscribe((user) => {
      if (socialUser && (socialUser.provider === 'facebook' || socialUser.provider === 'google') && isLogin && isNaN(+result?.user?.name) === false) {
        this.currentUserFacade.updateUser({ ...user, name: socialUser.name });
      }
      this.snackbarService.showSuccess(`Chúc bạn một ngày tốt lành! ${result.user.firstname ?? result.user.name ?? ''}`);
      if (navigate) {
        this.authRoutingGear.navigateHome();
      }
    });
  }

  loginFail(result: { message: string; caseName: string; user: { name: string }; error: string; provider: string }, user: any) {
    console.warn('login fail result:', result, user);
    switch (result.caseName) {
      case 'password-not-match':
        this.snackbarService.showError(`Mật khẩu không đúng, bạn có phải ${result.user.name}?`);
        break;
      case 'account-logged-in-with-social':
        this.snackbarService.showError(`Tài khoản đã tồn tại không sử dụng password, hãy đăng nhập lại với ${result.provider}`);
        break;
      case 'account-not-exist':
        if (user && user.provider) {
          this.confirmCreateNewAccount(user);
        } else {
          this.snackbarService.showError(
            `Không tìm thấy tài khoản, vui lòng tạo tài khoản mới nhé! Nếu bạn đã có tài khoản mà quên mất thông tin, vui lòng liên hệ fanpage để được hỗ trợ!`
          );
        }
        break;
      case 'email-not-verified':
        this.snackbarService.showWarning(`Tài khoản chưa xác minh email, chuyển sang quá trình xác minh!`);
        this.dialogService.closeAll();
        this.authRoutingGear.navigateToActiveAccount();
        break;
      case 'frictionless':
        this.confirmCreateNewAccount(user);
        break;
      case 'unknown':
        this.snackbarService.showWarning(`Tài khoản không xác định!`);
        break;
      case 'connect':
        break; // do nothing
      default:
        this.snackbarService.showError(result.message);
        break;
    }
    if (result.caseName !== 'connect') {
      console.warn('removing accesstoken');
      localStorage.removeItem('accessToken');
    }
  }
  // TODO: username
  private confirmCreateNewAccount(user: any) {
    const confirmDialog = this.dialogService.open(ConfirmComponent, {
      data: { question: `Bạn có muốn tạo tài khoản mới từ ${user.provider}?`, yes: 'Có', no: 'Không' },
    });
    // workaround dialog not display if not click inside https://github.com/angular/components/issues/24305
    (document.querySelector('app-confirm') as any)?.click();
    confirmDialog.afterClosed$.subscribe((result: any) => {
      if (result) {
        this.registerGear.createNewAccountFromSocial(user).subscribe((result: any) => {
          if (result) {
            this.setAccessToken(result);
            console.log('user on success login', user);
            this.getCurrentUser(result);
          }
        });
      }
    });
  }

  setAccessToken(result: { accessToken: string }) {
    if (result?.accessToken === 'null' || !result.accessToken) {
      console.log('removeItem access');
      localStorage.removeItem('accessToken');
    } else {
      console.log('setItem access', result?.accessToken);
      localStorage.setItem('accessToken', result?.accessToken);
    }
  }
}
