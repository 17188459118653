import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { domainEnvironment } from '@awread/global/environments';
@Injectable({ providedIn: 'root' })
export class AuthRoutingGear {
  loginCompleteRoute = '/profile';
  registerCompleteRoute = '/profile';
  constructor(private router: Router) {}

  navigateAfterCreateAccount(router: string = '/single/profile') {
    this.router.navigate([router]);
  }

  navigateAfterRegisterCompleted(router: string = '/') {
    this.router.navigate([router]);
  }

  navigateRegister() {
    this.router.navigateByUrl('/register');
  }
  navigateHome() {
    this.router.navigateByUrl('/');
  }
  navigateToActiveAccount() {
    this.router.navigateByUrl('/active');
  }
}
