import { CurrentUserStore } from './../states/current-user/current-user.store.js';
import { Router } from '@angular/router';
import { Inject, Injectable } from '@angular/core';
import { SnackbarService } from '@awread/global/packages';
import { ApolloAddon } from '@awread/global/apollo';

declare const FB: any;
@Injectable({ providedIn: 'root' })
export class LogoutGear {
  constructor(private apolloAddon: ApolloAddon, private router: Router, private snackbarService: SnackbarService, private currentUserStore: CurrentUserStore) {}

  async logout(redirect: string | 'no-redirect' = '/', isLogoutRequire?: boolean) {
    localStorage.clear();
    this.currentUserStore.reset();
    this.apolloAddon.clearStore();
    if (redirect == 'no-redirect') {
      if(isLogoutRequire) {
        this.snackbarService.showSuccess('Vui lòng đăng nhập lại');
      }
    } else {
      this.router.navigate([redirect]);
      this.snackbarService.showSuccess('Tạm biệt!');
    }
    window.location.reload();
    try {
      console.log('FB', FB);
      try {
        FB?.logout();
      } catch (error) {
        console.warn('social signout error', error);
      }
    } catch (error) {
      console.log();
    }
  }
}
